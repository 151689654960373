import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/Seo";

const PrivacyTemplate = ({ title, content }) => {

  return (
    <div className="w-full max-w-screen-xl px-6 mx-auto content">
      <div class="text-center md:text-left pt-8 pb-12 md:py-16"><h1 class="lg:pr-12">{title}</h1></div>
      <div
            
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
    </div>
  );
};

const PrivacyPage = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <Seo {...frontmatter} />
      <PrivacyTemplate
        title={frontmatter.title}
        content={data.markdownRemark.html}
      />
    </Layout>
  );
};

export const privacyQuery = graphql`
  query PrivacyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;

export default PrivacyPage;